import { gql, useQuery } from "@apollo/client";
import CheckIcon from "@heroicons/react/outline/CheckIcon";
import HeartIcon from "@heroicons/react/solid/HeartIcon";
import { useParams } from "react-router-dom";
import logo from "../monogram.svg";

interface InviteData {
  getInvite: Invite;
}

interface Invite {
  id: string;
  name: string;
  email: string;
  alternateEmails: string[];
  guestOf: string;
  guestRelationship: string;
  location: string;
  adults: number;
  children: number;
  isVip: boolean;
  sangeetInvite: boolean;
  weddingInvite: boolean;
  receptionInvite: boolean;
  comments: string;
  slug: string;
  sentAt: string;
  createdAt: string;
  updatedAt: string;
  rsvp: Rsvp;
}

interface Rsvp {
  id: string;
  ableToAttend: boolean;
  sangeetAttendees: string[];
  weddingAttendees: string[];
  receptionAttendees: string[];
  phone: string;
  comments: string;
  createdAt: string;
  updatedAt: string;
}

const GET_INVITE = gql`
  query ($id: ID!) {
    getInvite(id: $id) {
      id
      name
      email
      alternateEmails
      guestOf
      guestRelationship
      location
      adults
      children
      isVip
      sangeetInvite
      weddingInvite
      receptionInvite
      comments
      slug
      sentAt
      createdAt
      updatedAt
      rsvp {
        id
        ableToAttend
        attendees
        sangeetAttendees
        receptionAttendees
        weddingAttendees
        phone
        comments
      }
    }
  }
`;

function Schedule() {
  let params = useParams();
  const { loading, error, data } = useQuery<InviteData>(GET_INVITE, {
    variables: { id: params.slug },
  });
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :( {error}</p>;

  return (
    <>
      <main className="mt-10 sm:mt-16 sm:max-w-s md:max-w-4xl mx-auto pt-8 pb-24 px-1 sm:pt-4 sm:px-6 lg:px-8">
        <div className="rounded-md shadow-md md:rounded-lg md:shadow-xl m-auto bg-white px-4 py-3 sm:px-6">
          <div className="-mt-20 flex justify-center">
            <img
              className="object-contain w-24 md:w-32 select-none mb-1"
              src={logo}
              alt="Divya Plus Raja"
            />
          </div>
          <div className="text-center">
            <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">
              The Wedding Of
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Divya Sood & Raja Bhatia
            </p>
            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
              <br />
            </p>
          </div>

          {data.getInvite.sangeetInvite &&
            data.getInvite.rsvp.sangeetAttendees.length > 0 && (
              <>
                <div className="border-b border-indigo-500 border-dotted mb-8">
                  <div className="text-base max-w-none lg:max-w-none mt-5 mb-1">
                    <div>
                      <h2 className="text-sm sm:text-base text-indigo-600 font-semibold tracking-wide uppercase">
                        Sangeet
                      </h2>
                      <h3 className="text-xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                        Thursday, April 21, 2022
                      </h3>
                    </div>
                  </div>
                  <div className="py-2 sm:py-2 sm:grid sm:grid-cols-8 sm:gap-2">
                    <dt className="text-base font-semibold text-gray-500">
                      Time
                    </dt>
                    <dd className="text-base text-gray-900 sm:mt-0 sm:col-span-7">
                      <p className="font-semibold">6:00 PM until late</p>
                    </dd>
                  </div>
                  <div className="py-2 sm:py-2 sm:grid sm:grid-cols-8 sm:gap-2">
                    <dt className="text-base font-semibold text-gray-500">
                      Where
                    </dt>
                    <dd className="text-base text-gray-900 sm:mt-0 sm:col-span-7">
                      <p className="font-semibold">
                        St. Clair College Center For The Arts{" "}
                        <span className="font-medium">(map)</span>
                      </p>
                      <p>201 Riverside Drive West</p> <p>Windsor, ON</p>
                    </dd>
                  </div>

                  <div className="py-2 sm:py-2 sm:grid sm:grid-cols-8 sm:gap-2">
                    <dt className="text-base font-semibold text-gray-500">
                      Dress Code
                    </dt>

                    <dd className="text-base text-gray-900 sm:mt-0 sm:col-span-7">
                      <p className="">
                        Whatever you are most comfortable in. Bring your dancing
                        shoes...
                      </p>
                    </dd>
                  </div>
                </div>
              </>
            )}
          {data.getInvite.weddingInvite &&
            data.getInvite.rsvp.weddingAttendees.length > 0 &&
            data.getInvite.guestOf.toLocaleLowerCase().includes("raja") && (
              <>
                <div className="border-b border-indigo-500 border-dotted mb-8">
                  <div className="text-base max-w-none lg:max-w-none mt-10 mb-1">
                    <div>
                      <h2 className="text-sm sm:text-base text-indigo-600 font-semibold tracking-wide uppercase">
                        Baraat{" "}
                      </h2>
                      <h3 className="text-xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                        Friday, April 22, 2022
                      </h3>
                    </div>
                  </div>
                  <div className="py-2 sm:py-2 sm:grid sm:grid-cols-8 sm:gap-2">
                    <dt className="text-base font-semibold text-gray-500">
                      Time
                    </dt>
                    <dd className="text-base text-gray-900 sm:mt-0 sm:col-span-7">
                      <p className="font-semibold">3:00 PM to 4:00 PM</p>
                    </dd>
                  </div>
                  <div className="py-2 sm:py-2 sm:grid sm:grid-cols-8 sm:gap-2">
                    <dt className="text-base font-semibold text-gray-500">
                      Start
                    </dt>
                    <dd className="text-base text-gray-900 sm:mt-0 sm:col-span-7">
                      <p className="font-semibold">
                        McDougall & Riverside{" "}
                        <span className="font-medium">
                          (
                          <a
                            className="underline"
                            target="_blank"
                            href="https://goo.gl/maps/6WoHTbgwymENW7kn8"
                          >
                            map
                          </a>
                          )
                        </span>
                      </p>
                      <p>Windsor, ON</p>
                    </dd>
                  </div>
                  <div className="py-2 sm:py-2 sm:grid sm:grid-cols-8 sm:gap-2">
                    <dt className="text-base font-semibold text-gray-500">
                      End
                    </dt>
                    <dd className="text-base text-gray-900 sm:mt-0 sm:col-span-7">
                      <p className="font-semibold">
                        Caesars Windsor Hotel & Casino{" "}
                      </p>
                      <p>377 Riverside Dr East</p> <p>Windsor, ON</p>
                    </dd>
                  </div>
                  <div className="py-2 sm:py-2 sm:grid sm:grid-cols-8 sm:gap-2">
                    <dt className="text-base font-semibold text-gray-500">
                      Details
                    </dt>
                    <dd className="text-base text-gray-900 sm:mt-0 sm:col-span-7">
                      <p className="font-semibold">...</p>
                    </dd>
                  </div>
                </div>
              </>
            )}
          {data.getInvite.weddingInvite &&
            data.getInvite.rsvp.weddingAttendees.length > 0 && (
              <>
                <div className="border-b border-indigo-500 border-dotted mb-8">
                  <div className="text-base max-w-none lg:max-w-none mt-10 mb-1">
                    <div>
                      <h2 className="text-sm sm:text-base text-indigo-600 font-semibold tracking-wide uppercase">
                        Wedding{" "}
                        <span className="tracking-normal normal-case inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800">
                          New Venue
                        </span>
                      </h2>
                      <h3 className="text-xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                        Friday, April 22, 2022
                      </h3>
                    </div>
                  </div>
                  <div className="py-2 sm:py-2 sm:grid sm:grid-cols-8 sm:gap-2">
                    <dt className="text-base font-semibold text-gray-500">
                      Time
                    </dt>
                    <dd className="text-base text-gray-900 sm:mt-0 sm:col-span-7">
                      <p className="font-semibold">4:00 PM to 8:00 PM</p>
                    </dd>
                  </div>
                  <div className="py-2 sm:py-2 sm:grid sm:grid-cols-8 sm:gap-2">
                    <dt className="text-base font-semibold text-gray-500">
                      Where
                    </dt>
                    <dd className="text-base text-gray-900 sm:mt-0 sm:col-span-7">
                      <p className="font-semibold">
                        Augustus 27 - Caesars Hotel & Casino{" "}
                        <span className="font-medium">
                          (
                          <a
                            className="underline"
                            target="_blank"
                            href="https://goo.gl/maps/7MEs7A3VbFToVh2h6"
                          >
                            map
                          </a>
                          )
                        </span>
                        {"  "}
                        <span className="tracking-normal normal-case inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-semibold bg-green-100 text-green-600">
                          New Venue
                        </span>
                      </p>
                      <p>Augustus 27 - 377 Riverside Dr East</p>{" "}
                      <p>Windsor, ON</p>
                    </dd>
                  </div>

                  <div className="py-2 sm:py-2 sm:grid sm:grid-cols-8 sm:gap-2">
                    <dt className="text-base font-semibold text-gray-500">
                      Dress Code
                    </dt>

                    <dd className="text-base text-gray-900 sm:mt-0 sm:col-span-7">
                      <p className="">Whatever you are most comfortable in.</p>
                    </dd>
                  </div>
                </div>
              </>
            )}
          {data.getInvite.receptionInvite &&
            data.getInvite.rsvp.receptionAttendees.length > 0 && (
              <>
                <div className="border-b border-indigo-200 border-dotted mb-8">
                  <div className="text-base max-w-none lg:max-w-none mt-10 mb-1">
                    <div>
                      <h2 className="text-sm sm:text-base text-indigo-600 font-semibold tracking-wide uppercase">
                        Reception{" "}
                        <span className="tracking-normal normal-case inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800">
                          New Venue
                        </span>
                      </h2>
                      <h3 className="text-xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                        Saturday, April 23, 2022
                      </h3>
                    </div>
                  </div>
                  <div className="py-2 sm:py-2 sm:grid sm:grid-cols-8 sm:gap-2">
                    <dt className="text-base font-semibold text-gray-500">
                      Time
                    </dt>
                    <dd className="text-base text-gray-900 sm:mt-0 sm:col-span-7">
                      <p className="font-semibold">
                        6:00 PM until <em>very</em> late
                      </p>
                    </dd>
                  </div>

                  <div className="py-2 sm:py-2 sm:grid sm:grid-cols-8 sm:gap-2">
                    <dt className="text-base font-semibold text-gray-500">
                      Where
                    </dt>
                    <dd className="text-base text-gray-900 sm:mt-0 sm:col-span-7">
                      <p className="font-semibold">
                        Augustus Ballroom - Caesars Hotel & Casino{" "}
                        <span className="font-medium">
                          (
                          <a
                            className="underline"
                            target="_blank"
                            href="https://goo.gl/maps/7MEs7A3VbFToVh2h6"
                          >
                            map
                          </a>
                          )
                        </span>{" "}
                        <span className="tracking-normal normal-case inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-semibold bg-green-100 text-green-600">
                          New Venue
                        </span>
                      </p>
                      <p>377 Riverside Dr East</p> <p>Windsor, ON</p>
                    </dd>
                  </div>

                  <div className="py-2 sm:py-2 sm:grid sm:grid-cols-8 sm:gap-2">
                    <dt className="text-base font-semibold text-gray-500">
                      Dress Code
                    </dt>
                    <dd className="text-base text-gray-900 sm:mt-0 sm:col-span-7">
                      <p className="">Semi-Formal or Formal</p>
                    </dd>
                  </div>
                </div>
              </>
            )}
          <div className="mx-auto flex items-center justify-center">
            <HeartIcon className="h-8 w-8 text-red-300" aria-hidden="true" />
          </div>
        </div>
      </main>
    </>
  );
}

export default Schedule;
