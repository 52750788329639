import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Outlet } from "react-router-dom";
import ViewInvite from "./view";

interface Invite {
  id: string;
  name: string;
  email: string;
  alternateEmails: string[];
  guestOf: string;
  guestRelationship: string;
  location: string;
  adults: number;
  children: number;
  isVip: boolean;
  sangeetInvite: boolean;
  weddingInvite: boolean;
  receptionInvite: boolean;
  comments: string;
  slug: string;
  sentAt: string;
  createdAt: string;
  updatedAt: string;
  rsvp: Rsvp;
}

interface Rsvp {
  id: string;
  ableToAttend: boolean;
  sangeetAttendees: string[];
  weddingAttendees: string[];
  receptionAttendees: string[];
  phone: string;
  comments: string;
  createdAt: string;
  updatedAt: string;
}

interface RSVPStats {
  totalUnique: number;
  sangeet: number;
  wedding: number;
  reception: number;
}

interface InviteData {
  invites: Invite[];
  getRSVPStats: RSVPStats;
}

const LIST_INVITES = gql`
  query {
    invites {
      id
      name
      email
      alternateEmails
      guestOf
      guestRelationship
      location
      adults
      children
      isVip
      sangeetInvite
      weddingInvite
      receptionInvite
      comments
      slug
      sentAt
      createdAt
      updatedAt
      rsvp {
        id
        ableToAttend
        attendees
        sangeetAttendees
        receptionAttendees
        weddingAttendees
        phone
        comments
      }
    }
    getRSVPStats {
      totalUnique
      sangeet
      wedding
      reception
    }
  }
`;

function rsvpHelper(rsvp) {
  if (rsvp && rsvp.ableToAttend === true) {
    return "Attending";
  }
  if (rsvp && rsvp.ableToAttend === false) {
    return "Not Attending";
  }
}

function rsvpSangeetHelper(invite) {
  if (
    invite.rsvp &&
    invite.rsvp.ableToAttend === true &&
    invite.sangeetInvite === true &&
    invite.rsvp.sangeetAttendees.length > 0
  ) {
    return <strong>({invite.rsvp.sangeetAttendees.length})</strong>;
  }
  if (invite.rsvp && invite.rsvp.ableToAttend === false) {
    return "(0)";
  }
}

function rsvpWeddingHelper(invite) {
  if (
    invite.rsvp &&
    invite.rsvp.ableToAttend === true &&
    invite.weddingInvite === true &&
    invite.rsvp.weddingAttendees.length > 0
  ) {
    return <strong>({invite.rsvp.weddingAttendees.length})</strong>;
  }
  if (invite.rsvp && invite.rsvp.ableToAttend === false) {
    return "(0)";
  }
}

function rsvpReceptionHelper(invite) {
  if (
    invite.rsvp &&
    invite.rsvp.ableToAttend === true &&
    invite.receptionInvite === true &&
    invite.rsvp.receptionAttendees.length > 0
  ) {
    return <strong>({invite.rsvp.receptionAttendees.length})</strong>;
  }
  if (invite.rsvp && invite.rsvp.ableToAttend === false) {
    return "(0)";
  }
}

function Invites() {
  const [modal, setModal] = useState(null);

  const { loading, error, data } = useQuery<InviteData>(LIST_INVITES, {
    pollInterval: 5000,
  });
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div>
        <h3 className="mt-5 text-lg leading-6 font-medium text-gray-900">
          Stats
        </h3>
        <dl className="my-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
          <div
            key="statsData1"
            className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6"
          >
            <dt className="text-sm font-medium text-gray-500 truncate">
              Total Unique Attendees
            </dt>
            <dd className="mt-1 text-3xl font-semibold text-gray-900">
              {data.getRSVPStats.totalUnique}
            </dd>
          </div>
          <div
            key="statsData2"
            className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6"
          >
            <dt className="text-sm font-medium text-gray-500 truncate">
              Sangeet Attendees
            </dt>
            <dd className="mt-1 text-3xl font-semibold text-gray-900">
              {data.getRSVPStats.sangeet}
            </dd>
          </div>
          <div
            key="statsData3"
            className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6"
          >
            <dt className="text-sm font-medium text-gray-500 truncate">
              Wedding Attendees
            </dt>
            <dd className="mt-1 text-3xl font-semibold text-gray-900">
              {data.getRSVPStats.wedding}
            </dd>
          </div>
          <div
            key="statsData4"
            className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6"
          >
            <dt className="text-sm font-medium text-gray-500 truncate">
              Reception Attendees
            </dt>
            <dd className="mt-1 text-3xl font-semibold text-gray-900">
              {data.getRSVPStats.reception}
            </dd>
          </div>
        </dl>
      </div>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            {data.invites.length} Invitations
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the invitations, including RSVP details...
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            New Invite
          </button>
        </div>
      </div>
      <div className="mt-2 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Sangeet
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Wedding
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Reception
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      RSVP
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Sent At
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {data &&
                    data.invites.map((invite, inviteIdx) => (
                      <tr
                        key={invite.id}
                        className={
                          inviteIdx % 2 === 0 ? undefined : "bg-gray-100"
                        }
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <a href="#" onClick={() => setModal(invite)}>
                            {invite.name}
                          </a>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {invite.email}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {invite.sangeetInvite.toString()}{" "}
                          {rsvpSangeetHelper(invite)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {invite.weddingInvite.toString()}{" "}
                          {rsvpWeddingHelper(invite)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {invite.receptionInvite.toString()}{" "}
                          {rsvpReceptionHelper(invite)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {rsvpHelper(invite.rsvp)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {invite.sentAt && invite.sentAt.split("T")[0]}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <a
                            href={"/schedule/" + invite.id}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            Sched
                            <span className="sr-only">, {invite.name}</span>
                          </a>{" "}
                          |{" "}
                          <a
                            href={"/invites/" + invite.id}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            Invite Link
                          </a>
                          {invite.rsvp === null && (
                            <>
                              {" "}
                              |{" "}
                              <a
                                href={"/rsvp/" + invite.id}
                                className="text-indigo-600 hover:text-indigo-900"
                              >
                                RSVP Link
                              </a>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
      {modal && Object.keys(modal).length !== 0 && modal.rsvp && (
        <ViewInvite invite={modal} clearView={() => setModal({})} />
      )}
    </div>
  );
}

export default Invites;
