import { useQuery, gql } from "@apollo/client";
import HTMLFlipBook from "react-pageflip";
import { Link, useParams } from "react-router-dom";

interface Invite {
  id: string;
  name: string;
  email: string;
  alternateEmails: string[];
  guestOf: string;
  guestRelationship: string;
  location: string;
  adults: number;
  children: number;
  isVip: boolean;
  sangeetInvite: boolean;
  weddingInvite: boolean;
  receptionInvite: boolean;
  comments: string;
  slug: string;
  sentAt: string;
  createdAt: string;
  updatedAt: string;
}

const GET_INVITES = gql`
  query getInvite($id: ID!) {
    getInvite(id: $id) {
      id
      name
      email
      alternateEmails
      guestOf
      guestRelationship
      location
      adults
      children
      isVip
      sangeetInvite
      weddingInvite
      receptionInvite
      comments
      slug
      sentAt
      createdAt
      updatedAt
    }
  }
`;

const styles = {
  width: "100%",
  maxWidth: "540px",
  // width: "540px",
  paddingRight: "5px",
  paddingLeft: "5px",
  marginRight: "auto",
  marginLeft: "auto",
};

const styleCard = {};

let flipBook;
let totalPages;
let currentPage;
const nextButtonClick = () => {
  if (flipBook && flipBook.pageFlip()) {
    totalPages = flipBook.pageFlip().getPageCount();
    currentPage = flipBook.pageFlip().getCurrentPageIndex() + 1;
    console.log(currentPage);
    flipBook.pageFlip().flipNext();
  }
};

function FullCard(props) {
  //const [count, setCount] = useState(false);
  return (
    <div style={styles}>
      <HTMLFlipBook
        width={600}
        height={847}
        className={""}
        style={styleCard}
        startPage={0}
        size={"stretch"}
        minWidth={315}
        maxWidth={740}
        minHeight={420}
        maxHeight={1350}
        drawShadow={true}
        flippingTime={1000}
        usePortrait={true}
        startZIndex={0}
        ref={(el) => (flipBook = el)}
        autoSize={true}
        maxShadowOpacity={0.5}
        showCover={true}
        mobileScrollSupport={false}
        clickEventForward={true}
        useMouseEvents={false}
        swipeDistance={0}
        showPageCorners={true}
        disableFlipByClick={false}
      >
        <div className="demoPage" data-density="hard">
          <img src="/assets/DIVYA _ RAJA-01.jpg"></img>
        </div>

        <div className="demoPage hidden" key="sangeet">
          <img src="/assets/DIVYA _ RAJA-02.jpg"></img>
        </div>

        <div className="demoPage hidden" key="wedding">
          <img src="/assets/DIVYA _ RAJA-03.jpg"></img>
        </div>

        <div className="demoPage" key="reception">
          <img src="/assets/DIVYA _ RAJA-04.jpg"></img>
        </div>

        <div className="demoPage">
          <img src="/assets/DIVYA _ RAJA-05.jpg"></img>
        </div>
        <div className="demoPage" data-density="hard">
          <Link to={"/rsvp/" + props.getInvite.id}>
            <img src="/assets/DIVYA _ RAJA-06.jpg"></img>
          </Link>
          <Link to={"/rsvp/" + props.getInvite.id}>
            <button
              type="button"
              className="w-full px-6 py-3 border border-transparent shadow-sm text-center font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              RSVP NOW
            </button>
          </Link>
        </div>
      </HTMLFlipBook>
      <button
        type="button"
        className="w-full px-6 py-3 border border-transparent shadow-sm text-center font-medium text-white bg-gray-400 hover:bg-gray-700 focus:outline-none "
        onClick={nextButtonClick}
      >
        NEXT PAGE
      </button>
    </div>
  );
}

function sangeetCard(props) {
  return (
    <div style={styles}>
      <HTMLFlipBook
        width={600}
        height={847}
        className={""}
        style={styleCard}
        startPage={0}
        size={"stretch"}
        minWidth={315}
        maxWidth={740}
        minHeight={420}
        maxHeight={1350}
        drawShadow={true}
        flippingTime={1000}
        usePortrait={true}
        startZIndex={0}
        ref={(el) => (flipBook = el)}
        autoSize={true}
        maxShadowOpacity={0.5}
        showCover={true}
        mobileScrollSupport={false}
        clickEventForward={true}
        useMouseEvents={true}
        swipeDistance={0}
        showPageCorners={true}
        disableFlipByClick={false}
      >
        <div className="demoPage" data-density="hard">
          <img src="/assets/DIVYA _ RAJA-01.jpg"></img>
        </div>

        <div className="demoPage hidden" key="sangeet">
          <img src="/assets/DIVYA _ RAJA-02.jpg"></img>
        </div>

        <div className="demoPage">
          <img src="/assets/DIVYA _ RAJA-05.jpg"></img>
        </div>
        <div className="demoPage" data-density="hard">
          <Link to={"/rsvp/" + props.getInvite.id}>
            <img src="/assets/DIVYA _ RAJA-06.jpg"></img>
          </Link>
          <Link to={"/rsvp/" + props.getInvite.id}>
            <button
              type="button"
              className="w-full px-6 py-3 border border-transparent shadow-sm text-center font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              RSVP NOW
            </button>
          </Link>
        </div>
      </HTMLFlipBook>
      <button
        type="button"
        className="w-full px-6 py-3 border border-transparent shadow-sm text-center font-medium text-white bg-gray-400 hover:bg-gray-700 focus:outline-none "
        onClick={nextButtonClick}
      >
        NEXT PAGE
      </button>
    </div>
  );
}

function weddingCard(props) {
  return (
    <div style={styles}>
      <HTMLFlipBook
        width={600}
        height={847}
        className={""}
        style={styleCard}
        startPage={0}
        size={"stretch"}
        minWidth={315}
        maxWidth={740}
        minHeight={420}
        maxHeight={1350}
        drawShadow={true}
        flippingTime={1000}
        usePortrait={true}
        startZIndex={0}
        ref={(el) => (flipBook = el)}
        autoSize={true}
        maxShadowOpacity={0.5}
        showCover={true}
        mobileScrollSupport={false}
        clickEventForward={true}
        useMouseEvents={true}
        swipeDistance={0}
        showPageCorners={true}
        disableFlipByClick={false}
      >
        <div className="demoPage" data-density="hard">
          <img src="/assets/DIVYA _ RAJA-01.jpg"></img>
        </div>

        <div className="demoPage hidden" key="wedding">
          <img src="/assets/DIVYA _ RAJA-03.jpg"></img>
        </div>

        <div className="demoPage">
          <img src="/assets/DIVYA _ RAJA-05.jpg"></img>
        </div>
        <div className="demoPage" data-density="hard">
          <Link to={"/rsvp/" + props.getInvite.id}>
            <img src="/assets/DIVYA _ RAJA-06.jpg"></img>
          </Link>
          <Link to={"/rsvp/" + props.getInvite.id}>
            <button
              type="button"
              className="w-full px-6 py-3 border border-transparent shadow-sm text-center font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              RSVP NOW
            </button>
          </Link>
        </div>
      </HTMLFlipBook>
      <button
        type="button"
        className="w-full px-6 py-3 border border-transparent shadow-sm text-center font-medium text-white bg-gray-400 hover:bg-gray-700 focus:outline-none "
        onClick={nextButtonClick}
      >
        NEXT PAGE
      </button>
    </div>
  );
}

function receptionCard(props) {
  return (
    <div style={styles}>
      <HTMLFlipBook
        width={600}
        height={847}
        className={""}
        style={styleCard}
        startPage={0}
        size={"stretch"}
        minWidth={315}
        maxWidth={740}
        minHeight={420}
        maxHeight={1350}
        drawShadow={true}
        flippingTime={1000}
        usePortrait={true}
        startZIndex={0}
        ref={(el) => (flipBook = el)}
        autoSize={true}
        maxShadowOpacity={0.5}
        showCover={true}
        mobileScrollSupport={false}
        clickEventForward={true}
        useMouseEvents={true}
        swipeDistance={0}
        showPageCorners={true}
        disableFlipByClick={false}
      >
        <div className="demoPage" data-density="hard">
          <img src="/assets/DIVYA _ RAJA-01.jpg"></img>
        </div>

        <div className="demoPage" key="reception">
          <img src="/assets/DIVYA _ RAJA-04.jpg"></img>
        </div>

        <div className="demoPage">
          <img src="/assets/DIVYA _ RAJA-05.jpg"></img>
        </div>
        <div className="demoPage" data-density="hard">
          <Link to={"/rsvp/" + props.getInvite.id}>
            <img src="/assets/DIVYA _ RAJA-06.jpg"></img>
          </Link>
          <Link to={"/rsvp/" + props.getInvite.id}>
            <button
              type="button"
              className="w-full px-6 py-3 border border-transparent shadow-sm text-center font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              RSVP NOW
            </button>
          </Link>
        </div>
      </HTMLFlipBook>
      <button
        type="button"
        className="w-full px-6 py-3 border border-transparent shadow-sm text-center font-medium text-white bg-gray-400 hover:bg-gray-700 focus:outline-none "
        onClick={nextButtonClick}
      >
        NEXT PAGE
      </button>
    </div>
  );
}

function sangeetWeddingCard(props) {
  return (
    <div style={styles}>
      <HTMLFlipBook
        width={600}
        height={847}
        className={""}
        style={styleCard}
        startPage={0}
        size={"stretch"}
        minWidth={315}
        maxWidth={740}
        minHeight={420}
        maxHeight={1350}
        drawShadow={true}
        flippingTime={1000}
        usePortrait={true}
        startZIndex={0}
        ref={(el) => (flipBook = el)}
        autoSize={true}
        maxShadowOpacity={0.5}
        showCover={true}
        mobileScrollSupport={false}
        clickEventForward={true}
        useMouseEvents={true}
        swipeDistance={0}
        showPageCorners={true}
        disableFlipByClick={false}
      >
        <div className="demoPage" data-density="hard">
          <img src="/assets/DIVYA _ RAJA-01.jpg"></img>
        </div>

        <div className="demoPage hidden" key="sangeet">
          <img src="/assets/DIVYA _ RAJA-02.jpg"></img>
        </div>

        <div className="demoPage hidden" key="wedding">
          <img src="/assets/DIVYA _ RAJA-03.jpg"></img>
        </div>

        <div className="demoPage">
          <img src="/assets/DIVYA _ RAJA-05.jpg"></img>
        </div>
        <div className="demoPage" data-density="hard">
          <Link to={"/rsvp/" + props.getInvite.id}>
            <img src="/assets/DIVYA _ RAJA-06.jpg"></img>
          </Link>
          <Link to={"/rsvp/" + props.getInvite.id}>
            <button
              type="button"
              className="w-full px-6 py-3 border border-transparent shadow-sm text-center font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              RSVP NOW
            </button>
          </Link>
        </div>
      </HTMLFlipBook>
      <button
        type="button"
        className="w-full px-6 py-3 border border-transparent shadow-sm text-center font-medium text-white bg-gray-400 hover:bg-gray-700 focus:outline-none "
        onClick={nextButtonClick}
      >
        NEXT PAGE
      </button>
    </div>
  );
}

function weddingReceptionCard(props) {
  return (
    <div style={styles}>
      <HTMLFlipBook
        width={600}
        height={847}
        className={""}
        style={styleCard}
        startPage={0}
        size={"stretch"}
        minWidth={315}
        maxWidth={740}
        minHeight={420}
        maxHeight={1350}
        drawShadow={true}
        flippingTime={1000}
        usePortrait={true}
        startZIndex={0}
        ref={(el) => (flipBook = el)}
        autoSize={true}
        maxShadowOpacity={0.5}
        showCover={true}
        mobileScrollSupport={false}
        clickEventForward={true}
        useMouseEvents={true}
        swipeDistance={0}
        showPageCorners={true}
        disableFlipByClick={false}
      >
        <div className="demoPage" data-density="hard">
          <img src="/assets/DIVYA _ RAJA-01.jpg"></img>
        </div>

        <div className="demoPage hidden" key="wedding">
          <img src="/assets/DIVYA _ RAJA-03.jpg"></img>
        </div>

        <div className="demoPage" key="reception">
          <img src="/assets/DIVYA _ RAJA-04.jpg"></img>
        </div>

        <div className="demoPage">
          <img src="/assets/DIVYA _ RAJA-05.jpg"></img>
        </div>
        <div className="demoPage" data-density="hard">
          <Link to={"/rsvp/" + props.getInvite.id}>
            <img src="/assets/DIVYA _ RAJA-06.jpg"></img>
          </Link>
          <Link to={"/rsvp/" + props.getInvite.id}>
            <button
              type="button"
              className="w-full px-6 py-3 border border-transparent shadow-sm text-center font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              RSVP NOW
            </button>
          </Link>
        </div>
      </HTMLFlipBook>
      <button
        type="button"
        className="w-full px-6 py-3 border border-transparent shadow-sm text-center font-medium text-white bg-gray-400 hover:bg-gray-700 focus:outline-none "
        onClick={nextButtonClick}
      >
        NEXT PAGE
      </button>
    </div>
  );
}

function sangeetReceptionCard(props) {
  return (
    <div style={styles}>
      <HTMLFlipBook
        width={600}
        height={847}
        className={""}
        style={styleCard}
        startPage={0}
        size={"stretch"}
        minWidth={315}
        maxWidth={740}
        minHeight={420}
        maxHeight={1350}
        drawShadow={true}
        flippingTime={1000}
        usePortrait={true}
        startZIndex={0}
        autoSize={true}
        ref={(el) => (flipBook = el)}
        maxShadowOpacity={0.5}
        showCover={true}
        mobileScrollSupport={false}
        clickEventForward={true}
        useMouseEvents={true}
        swipeDistance={0}
        showPageCorners={true}
        disableFlipByClick={false}
      >
        <div className="demoPage" data-density="hard">
          <img src="/assets/DIVYA _ RAJA-01.jpg"></img>
        </div>

        <div className="demoPage hidden" key="sangeet">
          <img src="/assets/DIVYA _ RAJA-02.jpg"></img>
        </div>

        <div className="demoPage" key="reception">
          <img src="/assets/DIVYA _ RAJA-04.jpg"></img>
        </div>

        <div className="demoPage">
          <img src="/assets/DIVYA _ RAJA-05.jpg"></img>
        </div>
        <div className="demoPage" data-density="hard">
          <Link to={"/rsvp/" + props.getInvite.id}>
            <img src="/assets/DIVYA _ RAJA-06.jpg"></img>
          </Link>
          <Link to={"/rsvp/" + props.getInvite.id}>
            <button
              type="button"
              className="w-full px-6 py-3 border border-transparent shadow-sm text-center font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              RSVP NOW
            </button>
          </Link>
        </div>
      </HTMLFlipBook>
      <button
        type="button"
        className="w-full px-6 py-3 border border-transparent shadow-sm text-center font-medium text-white bg-gray-400 hover:bg-gray-700 focus:outline-none "
        onClick={nextButtonClick}
      >
        NEXT PAGE
      </button>
    </div>
  );
}

function Card() {
  let params = useParams();
  const { loading, error, data } = useQuery(GET_INVITES, {
    variables: { id: params.slug },
  });
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  if (
    data &&
    data.getInvite.sangeetInvite &&
    data.getInvite.weddingInvite &&
    data.getInvite.receptionInvite
  )
    return FullCard(data);
  if (
    data &&
    data.getInvite.sangeetInvite &&
    data.getInvite.weddingInvite === false &&
    data.getInvite.receptionInvite === false
  )
    return sangeetCard(data);
  if (
    data &&
    data.getInvite.sangeetInvite === false &&
    data.getInvite.weddingInvite === true &&
    data.getInvite.receptionInvite === false
  )
    return weddingCard(data);

  if (
    data &&
    data.getInvite.sangeetInvite === false &&
    data.getInvite.weddingInvite === false &&
    data.getInvite.receptionInvite === true
  )
    return receptionCard(data);
  if (
    data &&
    data.getInvite.sangeetInvite &&
    data.getInvite.weddingInvite &&
    data.getInvite.receptionInvite === false
  )
    return sangeetWeddingCard(data);
  if (
    data &&
    data.getInvite.sangeetInvite === false &&
    data.getInvite.weddingInvite &&
    data.getInvite.receptionInvite
  )
    return weddingReceptionCard(data);
  if (
    data &&
    data.getInvite.sangeetInvite &&
    data.getInvite.weddingInvite === false &&
    data.getInvite.receptionInvite
  )
    return sangeetReceptionCard(data);
}

export default Card;
