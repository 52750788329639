import { gql, useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Confetti from "react-confetti";
import Modal from "./modal";

const CREATE_RSVP = gql`
  mutation createRSVP(
    $inviteID: ID!
    $ableToAttend: Boolean!
    $attendees: [String!]!
    $weddingAttendees: [String!]!
    $sangeetAttendees: [String!]!
    $receptionAttendees: [String!]!
    $phone: String!
    $comments: NullString
  ) {
    createRSVP(
      input: {
        inviteID: $inviteID
        ableToAttend: $ableToAttend
        attendees: $attendees
        weddingAttendees: $weddingAttendees
        sangeetAtendees: $sangeetAttendees
        receptionAtendees: $receptionAttendees
        phone: $phone
        comments: $comments
      }
    ) {
      id
    }
  }
`;

const GET_INVITES = gql`
  query getInvite($id: ID!) {
    getInvite(id: $id) {
      id
      name
      email
      alternateEmails
      guestOf
      guestRelationship
      location
      adults
      children
      isVip
      sangeetInvite
      weddingInvite
      receptionInvite
      comments
      slug
      sentAt
      createdAt
      updatedAt
    }
  }
`;

function event(eventName, rsvp, handleEventCheckbox) {
  return (
    <div className="mt-10 sm:mt-0">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {eventName}
            </h3>
            <p className="mt-1 text-sm text-gray-600">
              Who will be attending the {eventName}?
              {eventName === "Sangeet" && (
                <>
                  <p className="mt-2 text-xs">
                    <strong>April 21st, 2022</strong> @{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://g.page/centre4arts?share"
                    >
                      St. Clair College Centre For the Arts
                    </a>
                    <p>Windsor, ON</p>
                  </p>
                </>
              )}
              {eventName === "Wedding" && (
                <>
                  <p className="mt-2 text-xs">
                    <strong>April 22nd, 2022</strong> @{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://goo.gl/maps/D3x72LhqzQB6FGdq9"
                    >
                      Riverfront Festival Plaza
                    </a>
                    <p>Windsor, ON</p>
                  </p>
                </>
              )}
              {eventName === "Reception" && (
                <>
                  <>
                    <p className="mt-2 text-xs">
                      <strong>April 23rd, 2022</strong> @{" "}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://goo.gl/maps/D3x72LhqzQB6FGdq9"
                      >
                        Riverfront Festival Plaza
                      </a>
                      <p>Windsor, ON</p>
                    </p>
                  </>
                </>
              )}
            </p>
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          <form action="#" method="POST">
            <div className="shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                <fieldset>
                  <legend className="text-base font-medium text-gray-900">
                    Attendees
                  </legend>
                  <div className="mt-4 space-y-4">
                    {rsvp.attendees
                      .filter((x) => x.firstName !== "" && x.lastName !== "")
                      .map((attendee, index) => {
                        return (
                          <div
                            className="flex items-start"
                            key={`${eventName}-${index}`}
                          >
                            <div className="flex items-center h-5">
                              <input
                                id={`${eventName}-${index}`}
                                name={`${eventName}-${index}`}
                                type="checkbox"
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                onChange={() =>
                                  handleEventCheckbox(
                                    eventName.toLowerCase(),
                                    index
                                  )
                                }
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label
                                htmlFor={`${eventName}-${index}`}
                                className="font-medium text-gray-700"
                              >
                                {attendee.firstName} {attendee.lastName}
                              </label>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </fieldset>
              </div>
              <div className="hidden px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

function RSVP() {
  let params = useParams();
  const [createRSVP, { data, loading, error }] = useMutation(CREATE_RSVP, {
    errorPolicy: "all",
  });
  const {
    loading: invLoading,
    error: invError,
    data: invData,
  } = useQuery(GET_INVITES, {
    variables: { id: params.slug },
  });

  const onSubmit = (event) => {
    event.preventDefault();
    console.log("submission prevented");
  };

  const [rsvp, setRsvp] = useState({
    able_to_attend: null,
    attendees: [{ firstName: "", lastName: "" }],
    sangeet_attendees: {},
    wedding_attendees: {},
    reception_attendees: {},
    phone: "",
    comments: "",
  });

  const handleAttendanceChange = (answer) => {
    setRsvp((rsvp) => ({ ...rsvp, able_to_attend: answer }));
  };

  const handlePhoneChange = (e) => {
    setRsvp((rsvp) => ({ ...rsvp, phone: e.target.value }));
  };

  const handleCommentsChange = (e) => {
    setRsvp((rsvp) => ({ ...rsvp, comments: e.target.value }));
  };

  const addAttendee = () => {
    let newAttendee = { firstName: "", lastName: "" };
    setRsvp((rsvp) => ({
      ...rsvp,
      attendees: [...rsvp.attendees, newAttendee],
    }));
  };

  const handleAttendeeChange = (index, event) => {
    let data = [...rsvp.attendees];
    data[index][event.target.name] = event.target.value;
    setRsvp((rsvp) => ({ ...rsvp, attendees: data }));
  };

  const handleEventCheckbox = (eventName, index) => {
    console.log(eventName, index);

    rsvp[eventName + "_attendees"][index] === true
      ? (rsvp[eventName + "_attendees"][index] = false)
      : (rsvp[eventName + "_attendees"][index] = true);
    setRsvp(rsvp);

    console.log(rsvp);
  };

  const genArray = (o) => {
    let a = [];
    Object.entries(o).forEach(([key, value]) => {
      if (value === true) {
        a.push(
          `${rsvp.attendees[key].firstName} ${rsvp.attendees[key].lastName}`
        );
      }
    });
    return a;
  };

  const submitRSVP = (event) => {
    event.preventDefault();
    console.log(rsvp);

    createRSVP({
      variables: {
        inviteID: params.slug,
        ableToAttend: rsvp.able_to_attend,
        attendees: rsvp.attendees.map((v) => `${v.firstName} ${v.lastName}`),
        weddingAttendees: genArray(rsvp["wedding_attendees"]),
        sangeetAttendees: genArray(rsvp["sangeet_attendees"]),
        receptionAttendees: genArray(rsvp["reception_attendees"]),
        phone: rsvp.phone,
        comments: rsvp.comments,
      },
    });
  };

  if (loading) return <div>Submitting...</div>;
  if (invLoading) return <div>Loading...</div>;
  if (invError) {
    console.log(error);
    return <div>Submission error! {invError.message}</div>;
  }
  if (error) {
    console.log(error);
    return <div>Submission error! {error.message}</div>;
  }
  if (data) {
    return (
      <>
        <Confetti />
        <div>Thank You!</div>
      </>
    );
  }
  if (invData) {
    console.log(invData);
    return (
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        {/* will you be able to attend */}
        {rsvp.able_to_attend == null && (
          <>
            <Modal onAnswer={handleAttendanceChange} />
          </>
        )}

        {/* events */}
        {rsvp.able_to_attend !== false && (
          <>
            {/* who will be attending */}
            <div className="mt-10 sm:mt-0">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      RSVP
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                      Who will be attending
                    </p>
                  </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form onSubmit={onSubmit}>
                    <div className="shadow overflow-hidden sm:rounded-md">
                      <div className="px-4 py-5 bg-white sm:p-6">
                        <div className="grid grid-cols-6 gap-6">
                          {rsvp.attendees.map((attendee, index) => {
                            return (
                              <>
                                <div
                                  className="col-span-6 sm:col-span-3"
                                  key={index}
                                >
                                  <label
                                    htmlFor="first-name"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    First name
                                  </label>
                                  <input
                                    type="text"
                                    name="firstName"
                                    id="first-name"
                                    value={attendee.firstName}
                                    autoComplete="given-name"
                                    onChange={(event) =>
                                      handleAttendeeChange(index, event)
                                    }
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                  />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                  <label
                                    htmlFor="last-name"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Last name
                                  </label>
                                  <input
                                    type="text"
                                    name="lastName"
                                    id="last-name"
                                    value={attendee.lastName}
                                    autoComplete="family-name"
                                    onChange={(event) =>
                                      handleAttendeeChange(index, event)
                                    }
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                  />
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>

                      {rsvp.attendees.length <
                        invData.getInvite.adults +
                          invData.getInvite.children && (
                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                          <button
                            onClick={addAttendee}
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Add Person
                          </button>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="hidden sm:block" aria-hidden="true">
              <div className="py-5">
                <div className="border-t border-gray-200" />
              </div>
            </div>

            {/* sangeet */}
            {invData.getInvite.sangeetInvite && (
              <>
                {event("Sangeet", rsvp, handleEventCheckbox)}
                <div className="hidden sm:block" aria-hidden="true">
                  <div className="py-5">
                    <div className="border-t border-gray-200" />
                  </div>
                </div>
              </>
            )}
            {/* wedding */}
            {invData.getInvite.weddingInvite && (
              <>
                {event("Wedding", rsvp, handleEventCheckbox)}
                <div className="hidden sm:block" aria-hidden="true">
                  <div className="py-5">
                    <div className="border-t border-gray-200" />
                  </div>
                </div>
              </>
            )}

            {/* reception */}
            {invData.getInvite.receptionInvite && (
              <>
                {event("Reception", rsvp, handleEventCheckbox)}
                <div className="hidden sm:block" aria-hidden="true">
                  <div className="py-5">
                    <div className="border-t border-gray-200" />
                  </div>
                </div>
              </>
            )}
          </>
        )}

        {/* final details */}

        <>
          <div className="mt-10 sm:mt-0">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Comments
                  </h3>
                  <p className="mt-1 text-sm text-gray-600"></p>
                </div>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <form action="#" method="POST">
                  <div className="shadow overflow-hidden sm:rounded-md">
                    <div className="px-4 py-5 bg-white sm:p-6">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Phone Number
                          </label>
                          <input
                            type="text"
                            name="phone"
                            id="phone-number"
                            autoComplete="phone-number"
                            placeholder="1-416-123-4567"
                            value={rsvp.phone}
                            onChange={handlePhoneChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>

                        <div className="col-span-6">
                          <label
                            htmlFor="about"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Comments
                          </label>
                          <div className="mt-1">
                            <textarea
                              id="comments"
                              name="comments"
                              rows={3}
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                              placeholder=""
                              onChange={handleCommentsChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                      <button
                        type="submit"
                        onClick={submitRSVP}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      </div>
    );
  }
}

export default RSVP;
