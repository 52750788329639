import React from "react";
import { render } from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Invites from "./invites/Invites";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import Card from "./invites/card";
import RSVP from "./invites/rsvp";
import Schedule from "./schedule";

const client = new ApolloClient({
  uri: "https://divyaplusraja.com/api/query",
  cache: new InMemoryCache(),
});

render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/admin/invites" element={<Invites />} />
          <Route path="/invites/:slug" element={<Card />} />
          <Route path="/schedule/:slug" element={<Schedule />} />
          <Route path="/rsvp/:slug" element={<RSVP />} />
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
